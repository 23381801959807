// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { getBaseApiUrlForEnv, getFirebaseConfig, getSplitConfig } from "./utils"

export const environment = {
  production: false,
  firebaseConfig: getFirebaseConfig("development"),
  baseApiUrl: getBaseApiUrlForEnv(),
  splitAPIKey: getSplitConfig("development"),
}
